<!-- eslint-disable no-undef -->
<template>
  <div>
    <el-form-item>
      <el-select
        :remote-method="getSuggestions"
        remote
        filterable
        v-model="value"
        @change="setSuggestion"
        placeholder="请选择"
      >
        <el-option
          v-for="item in mapListArr"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <ul id="suggestionList">
      <div></div>
    </ul>
    <el-form-item>
      <div id="container"></div>
    </el-form-item>
    <el-form-item>
      <td>
        <i class="fa fa-map-location-dot"></i>
        <span class="fs-sm">{{ address }}</span>
      </td>
    </el-form-item>
  </div>
</template>
<script>
import { Search } from "@element-plus/icons-vue";
let map, marker;
export default {
  name: "MapContainer",

  setup() {
    return {
      Search,
    };
  },
  data() {
    return {
      lat: 32.252936, // 经纬度可以先写死
      lng: 118.308673,
      address: null, // 用户的详细地址
      mapListArr: [],
      search: null,
      suggest: null,
      infoWindowList: Array(10),
      geocoder: null,
    };
  },

  methods: {
    initMap() {
      //定义地图中心点坐标

      var center = new TMap.LatLng(32.252936, 118.308673);
      //定义map变量，调用 TMap.Map() 构造函数创建地图

      map = new TMap.Map(document.getElementById("container"), {
        pitch: 30, //设置俯仰角度（0~45）
        zoom: 15, //设置地图缩放级别

        center: center, //设置地图中心点坐标
      });

      this.geocoder = new TMap.service.Geocoder();

      //创建并初始化MultiMarker
      marker = new TMap.MultiMarker({
        id: "marker-layer",
        map: map,
      });

      this.search = new TMap.service.Search({ pageSize: 10 });
      this.suggest = new TMap.service.Suggestion({
        // 新建一个关键字输入提示类
        pageSize: 20, // 返回结果每页条目数
        region: "", // 限制城市范围
        regionFix: false, // 搜索无结果时是否固定在当前城市
      });

      //监听点击事件添加marker
      map.on("click", (evt) => {
        console.log(evt);
        if (evt.poi == null) {
          marker.setGeometries([]);
        } else {
          marker.add([
            {
              id: "1",
              position: evt.latLng,
            },
          ]);
          marker.updateGeometries([
            {
              id: "1",
              position: evt.latLng,
            },
          ]);

          this.geocoder.getAddress({ location: evt.latLng }).then((res) => {
            console.log(res);
            if (res.status === 0) {
              this.emit(res.result.ad_info, res.result.address);
            }
          });
        }
      });
    },
    getSuggestions(value) {
      console.log(value);
      this.mapListArr = [];
      this.value = value;
      // 使用者在搜索框中输入文字时触发
      var suggestionListContainer = document.getElementById("suggestionList");
      suggestionListContainer.innerHTML = "";
      var keyword = value;
      if (keyword) {
        this.suggest
          .getSuggestions({ keyword: keyword, location: map.getCenter() })
          .then((result) => {
            // 以当前所输入关键字获取输入提示
            suggestionListContainer.innerHTML = "";
            this.suggestionList = result.data;
            this.suggestionList.forEach((item, index) => {
              this.mapListArr.push({
                label: item.title,
                value: index,
              });
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    // 下拉框自己该成 slect change
    setSuggestion(index) {
      this.copyIndex = index;
      // 点击输入提示后，于地图中用点标记绘制该地点，并显示信息窗体，包含其名称、地址等信息
      this.infoWindowList.forEach((infoWindow) => {
        infoWindow.close();
      });
      this.infoWindowList.length = 0;

      marker.setGeometries([]);
      let addressInfo = this.suggestionList[index];
      marker.updateGeometries([
        {
          id: "1", // 点标注数据数组
          position: addressInfo.location,
        },
      ]);
      map.setCenter(addressInfo.location);

      this.emit(addressInfo, addressInfo.address);
      marker.on("click", (e) => {
        this.infoWindowList[Number(e.geometry.id)].open();
      });
    },

    emit(addressInfo, address) {
      this.address = address;

      console.log(addressInfo);
      let sj_ads =
        addressInfo.province +
        "/" +
        addressInfo.city +
        "/" +
        addressInfo.district;
      let addressData = {
        uin: this.$route.query.uin,
        ads: sj_ads,
        address: address,
        lonLat: addressInfo.location.lng + "," + addressInfo.location.lat,
      };
      this.$emit("update:addressData", addressData);
    },
    loadScript() {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "https://map.qq.com/api/gljs?v=1.exp&libraries=service&key=OQQBZ-EEXEJ-WR6FR-FVV7Y-4YX23-OCBS2&callback=init";
      script.onload = script.onreadystatechange = () => {
        if (
          !this.readyState ||
          this.readyState === "loaded" ||
          this.readyState === "complete"
        ) {
          this.initMap();
          script.onload = script.onreadystatechange = null;
        }
      };
      document.body.appendChild(script);
    },
  },
  mounted() {
    this.loadScript();
  },
};
</script>
<style scope>
#container {
  height: 100%;
  width: 100%;
  height: 350px;
}
</style>
